<template>
  <v-container class="fill-height pa-0" fluid>
    <v-row align="start" justify="center" class="fill-height pa-0">
      <v-col cols="12" sm="12" md="8" class="pa-0 pa-md-4">
        <v-progress-linear
          :active="loading"
          indeterminate
          color="primary"
          class="mb-0"
        ></v-progress-linear>
        <v-card class="pt-0">
          <v-card-title class="d-flex justify-center">
            Suivi d'intervention
          </v-card-title>
          <v-card-subtitle class="d-flex justify-center">
            Description: {{ ticket && ticketName }}<br />Réference:
            {{ ticket && formatID(ticket.id) }}
          </v-card-subtitle>

          <v-card-text pa-2>
            <ticket-action
              :ticket-id="Number.parseInt(ticketId)"
              :entity-id="ticket.entities_id"
              :ticket-status="ticket.status"
              @newComment="addNewITILFollowup"
              @newSolution="addNewITILSolution"
              @newTask="addNewTask"
            ></ticket-action>
            <time-line
              v-if="list.length > 0"
              :list="list"
              :selected-ticket="
                ticket
                  ? {
                      name: ticket.name,
                      date_creation: ticket.date,
                      content: ticket.content
                    }
                  : null
              "
            ></time-line>
            <v-skeleton-loader
              ref="skeleton"
              type="list-item-avatar-three-line"
              class="mx-auto"
              v-if="loading"
            ></v-skeleton-loader>
            <div class="d-flex flex-column" v-if="loaded && list.length === 0">
              <v-timeline
                :dense="$vuetify.breakpoint.smAndDown"
                class="timeline__wrapper"
              >
                <v-timeline-item left color="warning" icon class="text-right">
                  <template slot="icon">
                    <v-icon x-large class="white--text"
                      >mdi-information-outline</v-icon
                    >
                  </template>
                  <span slot="opposite">
                    <v-chip>
                      <v-icon color="success">access_time </v-icon>
                      {{
                        moment(ticket.date_creation).format("DD/MM/YYYY  HH:mm")
                      }}
                    </v-chip>
                  </span>
                  <div style="width: 100%" class="d-flex justify-end">
                    <div
                      class="ma-2 success--text text-justify incident description pb-2 pt-2"
                      color="success"
                      outlined
                      x-large
                    >
                      Déclaration d'intervention:
                      <div
                        v-if="ticket.content"
                        v-html="decodeContent(ticket.content)"
                      ></div>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item right color="grey" icon>
                  <template slot="icon">
                    <v-icon x-large class="white--text"
                      >mdi-information-outline</v-icon
                    >
                  </template>
                  <v-chip class="ma-2" color="grey" outlined>
                    Aucun élement de suivi
                  </v-chip>
                </v-timeline-item>
              </v-timeline>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Decoder } from "../mixins/decodeContent";
import { ticketActionPermissions, formatID, loadStates } from "../utils";
import TimeLine from "../components/TimeLine";
import TicketAction from "../components/TicketAction";
import moment from "../plugins/moment";
import { mapGetters, mapMutations } from "vuex";
import { ref, computed } from "@vue/composition-api";
import { setupTicketsComponent } from "../composition/setupTicketsData";
import USER_API from "../api/user";

export default {
  components: { TimeLine, TicketAction },
  data() {
    return {
      entity: null,
      loading: true,
      titleText: "",
      ticketName: ""
    };
  },
  setup(props, { root: { $route, $store } }) {
    const ticketId = ref($route.params.ticketId);
    const tId = $route.params.ticketId;
    const tasks = computed(() =>
      $store.getters.getTicketsTasks.filter(
        task => "" + task.tickets_id === "" + tId
      )
    );
    const followUps = computed(() =>
      $store.getters.getAllITILFollowups.filter(
        task => "" + task.items_id === "" + tId
      )
    );
    const itSolutions = computed(() =>
      $store.getters.getAllITILSolutions.filter(
        solution => "" + solution.items_id === "" + tId
      )
    );

    return {
      ...setupTicketsComponent(tId, tasks, followUps, itSolutions),
      tasks,
      followUps,
      itSolutions,
      ticketId
    };
  },
  mixins: [Decoder],
  computed: {
    ...mapGetters([
      "isAuth",
      "getTicketsTasksLoadStatus",
      "getAllITILSolutionsLoadStatus",
      "getAllITILFollowupsLoadStatus"
    ]),
    AddCommentAction() {
      let flag = false;
      for (let i = 0; i < this.getUserProfiles.length; i++) {
        if (
          ticketActionPermissions.checkPermission(1, this.getUserProfiles[i])
        ) {
          flag = true;
        }
      }
      return flag;
    },
    AddResolutionAction() {
      let flagAdd = false;
      for (let i = 0; i < this.getUserProfiles.length; i++) {
        if (
          ticketActionPermissions.checkPermission(2, this.getUserProfiles[i])
        ) {
          flagAdd = true;
        }
      }
      return flagAdd;
    },
    dataLoaded() {
      return (
        this.getTicketsTasksLoadStatus === loadStates.SUCCESS &&
        this.getAllITILSolutionsLoadStatus === loadStates.SUCCESS &&
        this.getAllITILFollowupsLoadStatus === loadStates.SUCCESS
      );
    }
  },
  methods: {
    ...mapMutations(["setActiveEntity"]),
    formatID,
    moment,
    getColor(status = 1) {
      switch (status) {
        case 4:
          return "red accent-2";
        case 2:
          return "success";
        default:
          return "indigo";
      }
    },
    getAvatarName: function(user_name) {
      return user_name.toUpperCase().substring(0, 2);
    },
    loadActiveEntity(entityId) {
      this.isAuth &&
        USER_API.setActiveEntity(entityId).then(() => {
          USER_API.getEntity().then(({ data }) => {
            this.entity = Array.isArray(data)
              ? data.find(entity => entity.id == entityId)
              : data;
            this.setActiveEntity(this.entity);
            this.setTitle();
          });
        });
    },
    setTitle() {
      try {
        const title = this.entity && this.entity.completename.replace(" ", "");
        const parentsEntities = title.split(">");
        if (this.$vuetify.breakpoint.smAndDown) {
          this.titleText =
            parentsEntities[3].slice(0, 10) +
            " > " +
            parentsEntities[4].slice(0.1);
        } else {
          this.titleText = parentsEntities[3] + " > " + parentsEntities[4];
        }
      } catch (error) {
        this.titleText = this.ticketName;
      }
    }
  },
  created() {
    if (this.isAuth && this.dataLoaded) {
      this.loadData();
      //this.loadActiveEntity();
    }
  },
  watch: {
    isAuth() {
      if (this.isAuth && this.dataLoaded) {
        this.loadData();
      }
    },
    dataLoaded() {
      if (this.isAuth && this.dataLoaded) {
        this.loadData();
      }
    },
    ticket() {
      if (this.ticket && this.ticket.entities_id) {
        this.loadActiveEntity(this.ticket.entities_id);
        this.ticketName = JSON.parse(this.ticket.name).titre;
      }
    }
  }
};
</script>

<style scoped></style>
